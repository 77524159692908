export function fmtLocalDate(template, rawDate) {
  const specs = "YYYY:MM:DD:HH:mm:ss".split(":")
  const convertedDate = rawDate instanceof Date ? rawDate : new Date(rawDate)
  const date = new Date(
    convertedDate.getTime() - new Date().getTimezoneOffset() * 6e4 ||
      Date.now() - new Date().getTimezoneOffset() * 6e4
  )
  return date
    .toISOString()
    .split(/[-:.TZ]/)
    .reduce(
      (template, item, i) => template.split(specs[i]).join(item),
      template
    )
}
