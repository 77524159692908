import React, { useState, useEffect, useRef } from "react"
import $ from "jquery"
import { TextField, InputAdornment, Icon, IconButton } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ReactHtmlParser from "react-html-parser"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { siteMetaCtx } from "../store/siteMeta/context"
import { RoomFullPage } from "../components/RoomPage/RoomFullPage"
import { fmtLocalDate } from "../utils/date"
import { Breadcrumb } from "../ui/Breadcumb"
import { config } from "../config"

const useStyles = makeStyles(
  theme => ({
    textField: {
      width: 250,
      "& .Mui-focused": {
        "& $InputAdornmentIcon": {
          color: "#e8cb74",
        },
      },
      "&:hover": {
        "& $InputAdornmentIcon": {
          color: theme.palette.text.primary,
        },
      },
    },
    InputAdornmentIcon: {
      "&:hover": {
        background: "transparent",
        color: "#e8cb74",
      },
    },
  }),
  { name: "NewsTemplate" }
)
const INIT_PAGIN_META = {
  currentPage: 1,
  postPerPage: 5,
  totalPost: 0,
  totalPages: 1,
  categoryId: null,
}

const NewsTemplate = ({ pageContext, uri, ...props }) => {
  const { basic_info, full_page, content, ...restData } = pageContext.acf
  const [newsList, setNewsList] = useState([])
  const [viewPostId, setViewPostId] = useState(null)
  const [currentPost, setCurrentPost] = useState(null)
  const [paginMeta, setPaginMeta] = useState(INIT_PAGIN_META)
  const [searchKey, setSearchKey] = useState(null)
  const searchBtnRef = useRef(null)

  const [isDetail, setIsDetail] = useState(Boolean(viewPostId))
  const classes = useStyles()

  useEffect(() => {
    setIsDetail(Boolean(viewPostId))
    if (props.location.hash !== "") {
      setViewPostId(props.location.hash.substring(1))
      setIsDetail(true)
      // query = JSON.parse(
      //   '{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      //   function(key, value) {
      //     return key === "" ? value : decodeURIComponent(value)
      //   }
      // )
    }

    ;(async () => {
      const categoriesIdInWp = await fetch(
        `${config.API_HOST}/wp-json/wp/v2/categories?slug=posts&_fields=id,name,slug`
      )
        .then(data => data.json())
        .then(categories => categories[0] && categories[0].id)

      if (categoriesIdInWp) {
        await getRemoteNewsList(1, categoriesIdInWp)
      }
    })()
  }, [])

  useEffect(() => {
    if (viewPostId) {
      ;(async () => {
        await fetch(
          `${config.API_HOST}/wp-json/wp/v2/posts?&_fields=id,date,acf&include=${viewPostId}`
        )
          .then(data => data.json())
          .then(data => {
            setCurrentPost(data)
          })
      })()
    }
  }, [viewPostId])

  useEffect(() => {
    if (searchKey && paginMeta.categoryId) {
      getRemoteNewsList()
    }
  }, [searchKey])

  async function getRemoteNewsList(
    page = 1,
    categoryId = paginMeta.categoryId
  ) {
    const newsResp = await fetch(
      `${
        config.API_HOST
      }/wp-json/wp/v2/posts?_fields=id,slug,date,acf&page=${page}&per_page=${
        paginMeta.postPerPage
      }&categories[]=${categoryId}&search=${searchKey || ""}`
    ).then(async function(data) {
      const result = await data.json()
      return {
        data: result,
        totalPages:
          (data.headers.get("X-WP-TotalPages") &&
            parseInt(data.headers.get("X-WP-TotalPages"))) ||
          1,
        totalPost:
          (data.headers.get("X-WP-Total") &&
            parseInt(data.headers.get("X-WP-Total"))) ||
          0,
      }
    })

    setPaginMeta(prev => ({
      ...prev,
      currentPage: page,
      totalPages: newsResp.totalPages,
      totalPost: newsResp.totalPost,
      categoryId: categoryId,
    }))
    setNewsList(newsResp.data)
  }

  function setScrollToScreen() {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $("#content").offset().top - 10,
      },
      500
    )
  }

  const handleSearch = searchKey => {
    setSearchKey(searchKey)
  }

  const handleViewDetail = newsId => {
    window.history.pushState("", "", "/news#" + newsId)
    setViewPostId(newsId)
    setIsDetail(true)
    setScrollToScreen()
  }

  const handleNavigate = async (action, page) => {
    if (action === "prev" && paginMeta.currentPage - 1 > 0) {
      await getRemoteNewsList(paginMeta.currentPage - 1)
    } else if (
      action === "next" &&
      paginMeta.currentPage + 1 <= paginMeta.totalPages
    ) {
      await getRemoteNewsList(paginMeta.currentPage + 1)
    } else if (action === "to") {
      if (page && page !== paginMeta.currentPage) {
        await getRemoteNewsList(page)
      }
    } else {
      return
    }
    setScrollToScreen()
  }

  return (
    <siteMetaCtx.Provider value={{ uri }}>
      <Layout>
        <SEO
          lang="zh-TW"
          title={basic_info.seo_title}
          description={basic_info.seo_description}
        ></SEO>
        <RoomFullPage data={full_page} />
        <section className="position-relative">
          <div className="news-template">
            <Breadcrumb
              paths={basic_info.breadcumb.map(path => ({
                text: path.name,
                link_url: path.link_name,
              }))}
            />
            <div id="content" className="wrapper">
              {!isDetail && (
                <div className="row mb-4">
                  <div className="col-auto">
                    <TextField
                      variant="outlined"
                      size="small"
                      color="primary"
                      placeholder="輸入關鍵字查詢"
                      inputProps={{
                        ref: searchBtnRef,
                      }}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          handleSearch(e.target.value)
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton
                              disableRipple
                              disableFocusRipple
                              onClick={() => {
                                handleSearch(searchBtnRef.current.value)
                              }}
                              className={classes.InputAdornmentIcon}
                            >
                              <Icon>search</Icon>
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      className={classes.textField}
                    />
                  </div>
                </div>
              )}
              <div className="divider" />
              {isDetail && currentPost && currentPost[0] ? (
                <div className="row news-block-detail">
                  <div className="col-24 mb-4 title">
                    {currentPost[0].acf.title}
                  </div>
                  <div className="col-24 mb-4 content">
                    {ReactHtmlParser(currentPost[0].acf.post_content)}
                  </div>
                  <div className="col-auto time">
                    發布時間:{" "}
                    {fmtLocalDate("YYYY.MM.DD HH:MM", currentPost[0].date)}
                  </div>
                </div>
              ) : (
                newsList.map(news => (
                  <div className="row news-block " key={news.id}>
                    <div className="col-xs-24 col-md-auto mb-3 mb-lg-0">
                      <img
                        src={news.acf.image.url}
                        alt={news.acf.image.alt}
                        className="image"
                      />
                    </div>
                    <div className="row col align-content-start no-gutters">
                      <div className="row col-24 justify-content-between align-content-start">
                        <div className="col-auto mb-3">
                          <p className="title">{news.acf.title}</p>
                        </div>
                        <div className="col-auto pr-0 time">
                          {fmtLocalDate("YYYY.MM.DD", news.date)}
                        </div>
                      </div>
                      <div className="col-24 content">
                        {ReactHtmlParser(news.acf.content_brief)}
                      </div>
                      <button
                        onClick={() => handleViewDetail(news.id)}
                        className="btn btn-outline-light mx-auto mt-3 mx-lg-0 mt-lg-0 main-btn booking-btn"
                      >
                        {news.acf.button_text}
                      </button>
                    </div>
                  </div>
                ))
              )}
              <div className="row justify-content-center py-5">
                <div className="col-auto">
                  {(isDetail && (
                    <button
                      onClick={() => {
                        window.history.pushState("", "", "/news#")
                        setViewPostId(null)
                        setIsDetail(false)
                        setScrollToScreen()
                      }}
                      className="btn btn-outline-light mx-auto mt-3 mx-md-0 mt-md-0 main-btn"
                    >
                      回上層
                    </button>
                  )) || (
                    <nav aria-label="Page navigation">
                      <ul className="pagination ">
                        <li
                          className={`page-item ${
                            paginMeta.currentPage === 1 ? "disabled" : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            onClick={() => handleNavigate("prev")}
                            aria-label="Previous"
                          >
                            <span aria-hidden="true">&laquo;&nbsp;Prev</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>
                        {Array(paginMeta.totalPages)
                          .fill("")
                          .map((o, idx) => (
                            <li
                              className={`page-item ${
                                paginMeta.currentPage === idx + 1
                                  ? "active"
                                  : ""
                              }`}
                              key={idx}
                            >
                              <span
                                className="page-link"
                                onClick={() => handleNavigate("to", idx + 1)}
                              >
                                {idx + 1}
                              </span>
                            </li>
                          ))}
                        <li
                          className={`page-item ${
                            paginMeta.currentPage === paginMeta.totalPages
                              ? "disabled"
                              : ""
                          }`}
                        >
                          <span
                            className="page-link"
                            aria-label="Next"
                            onClick={() => handleNavigate("next")}
                          >
                            <span aria-hidden="true">Next&nbsp;&raquo;</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </siteMetaCtx.Provider>
  )
}

export default NewsTemplate
